const input_url = document.createElement('input');
input_url.type = 'hidden';
input_url.name = 'project_fields[source_url]';
input_url.id = 'project_fields[source_url]';
input_url.value = window.location.href;

const input_ip = document.createElement('input');
input_ip.type = 'hidden';
input_ip.name = 'project_fields[ip]';
input_ip.id = 'project_fields[ip]';
const input_user_agent = document.createElement('input');
input_user_agent.type = 'hidden';
input_user_agent.name = 'project_fields[user_agent]';
input_user_agent.id = 'project_fields[user_agent]';
input_user_agent.value = navigator.userAgent;
const input_privacy = document.createElement('input');
input_privacy.type = 'hidden';
input_privacy.name = 'privacy[privacy]';
input_privacy.id = 'privacy[privacy]';
input_privacy.value=1;
const input_privacy_tc = document.createElement('input');
input_privacy_tc.type = 'hidden';
input_privacy_tc.name = 'privacy[termini_condizioni]';
input_privacy_tc.id = 'privacy[termini_condizioni]';
input_privacy_tc.value=1;
const input_privacy_nl = document.createElement('input');
input_privacy_nl.type = 'hidden';
input_privacy_nl.name = 'privacy[newsletter_cloudcare]';
input_privacy_nl.id = 'privacy[newsletter_cloudcare]';
input_privacy_nl.value=1;

const request = new XMLHttpRequest();
request.open("GET", "https://api.ipify.org", false); 
request.send(null);
if (request.status === 200) {  
  input_ip.value = request.responseText;
}

document.addEventListener('DOMContentLoaded', () => {
    const form = document.getElementsByTagName('form')[0];
    form.appendChild(input_url);
    form.appendChild(input_ip);
    form.appendChild(input_user_agent);
    form.appendChild(input_privacy);
    form.appendChild(input_privacy_tc);
    form.appendChild(input_privacy_nl);
});